import { render, staticRenderFns } from "./RosskoDetail.vue?vue&type=template&id=068ab484"
import script from "./RosskoDetail.vue?vue&type=script&lang=ts"
export * from "./RosskoDetail.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("@/assets/scss/components/detail.scss?vue&type=style&index=0&prod&lang=scss&external")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports